import { Box } from "@mui/system";
import React from "react";
import ButtonForm from "../UI/ButtonForm/ButtonForm";
import styles from "./SliderMobileItem.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { setActiveSlide } from "../../redux/slices/SliderSlice";
import { useDispatch, useSelector } from "react-redux";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { v4 as uuidv4 } from "uuid";
import imgSliderBackPattern from "../../img/SliderBackPattern.png";
import imgSliderBackgroundAfter from "../../img/slider_item_bg.png";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import ImageGallery from "react-image-gallery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/fontawesome-free-solid";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";

const id = uuidv4();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SliderMobileItem({ number, title, img, desc, content, galleryFiles }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const ActiveSlide = useSelector((state) => state.slider.activeSlide);

  const itemRef = React.useRef(null);
  const NumberRef = React.useRef(null);
  const NumberValueRef = React.useRef(null);
  const NumberParent1Ref = React.useRef(null);
  const NumberParent2Ref = React.useRef(null);
  const TitleWrapperRef = React.useRef(null);
  const TitleRef = React.useRef(null);
  const ActionsRef = React.useRef(null);

  const [HasMore, setHasMore] = React.useState(false);
  const [WidthSlider, setWidthSlider] = React.useState(0);
  const [WidthSliderItem, setWidthSliderItem] = React.useState(0);
  const [SaveTransform, setSaveTransform] = React.useState("");

  React.useEffect(() => {
    setWidthSlider(itemRef.current.parentElement.clientWidth);
    setWidthSliderItem(itemRef.current.clientWidth);
  }, [itemRef]);

  React.useEffect(() => {
    if (ActiveSlide !== number) {
      handleClose();
    }
  }, [ActiveSlide]);

  /**
   * Открыть расширенный просмотр проекта
   * @param {*} e
   * @returns
   */
  const handleMore = (e) => {
    if (HasMore) return;
    setHasMore(true);
  };

  /**
   * Закрыть расширенный просмотр проекта
   * @param {*} e
   */
  const handleClose = () => {
    setHasMore(false);
  };

  const handleMouseOver = (e) => {
    console.log(e);
  };

  let clsSlide = [styles.SliderItem];
  if (HasMore) {
    clsSlide.push(styles.SliderItemMore);
  }

  let clsSliderItemWrapper = [styles.SliderItemWrapper];
  if (HasMore) {
    clsSliderItemWrapper.push(styles.SliderItemWrapperMore);
  }

  // Content
  var images = [];
  const refImg = React.useRef(null);
  if (galleryFiles !== null) {
    galleryFiles.forEach((galleryFile) => {
      let img = {
        fullscreen: galleryFile.path,
        original: galleryFile.path,
        thumbnail: galleryFile.path,
        originalHeight: 300,
      };
      images.push(img);
    });
  }
  const renderCustomControls = () => {
    if (refImg !== null && refImg.current !== null && images !== null && images.length > 0) {
      let index = refImg.current.getCurrentIndex();
      let img = images[index];
      return (
        <a href={img.fullscreen} target="_blank" className={styles.ButtonFullscreen}>
          <FontAwesomeIcon icon={faExpand} color="white" size="lg" />
        </a>
      );
    }
  };
  return (
    <>
      <Box className={styles.SliderItemWrapper} ref={itemRef}>
        <Box className={styles.SliderBackPattern} onMouseOver={handleMouseOver}>
          <img src={imgSliderBackPattern} alt="" />
        </Box>
        <Box id={id} className={styles.SliderItem}>
          <Box className={styles.SliderBackground} sx={{ backgroundImage: `url(${img})` }}></Box>
          <Box className={styles.SliderBackgroundAfter} sx={{ backgroundImage: `url(${imgSliderBackgroundAfter})` }}></Box>
          <Box className={styles.Number} ref={NumberRef}>
            <Box className={styles.NumberParent1} ref={NumberParent1Ref}></Box>
            <Box className={styles.NumberValue} ref={NumberValueRef}>
              {number < 10 ? `0${number}` : number}
            </Box>
            <Box className={styles.TitleMore}>{title}</Box>
          </Box>
          <Box className={styles.TitleWrapper} ref={TitleWrapperRef}>
            <Box className={styles.Title} ref={TitleRef}>
              {title}
            </Box>
          </Box>
          <Box className={styles.Content}>
            <Box className={styles.ContentText} sx={{ opacity: HasMore ? 0 : 1, pointerEvents: HasMore ? "none" : "auto" }}>
              {desc}
            </Box>
          </Box>
          <Box className={styles.Actions} ref={ActionsRef}>
            <ButtonForm
              sx={{ minWidth: 150 }}
              onClick={(e) => {
                if (HasMore) {
                  navigate("");
                  handleClose();
                } else {
                  handleMore(e);
                }
              }}
            >
              {HasMore ? "Закрыть" : "Подробнее"}
            </ButtonForm>
          </Box>
        </Box>
      </Box>

      <Dialog fullScreen open={HasMore} onClose={handleClose} TransitionComponent={Transition}>
        <Box id={id} className={styles.SliderItemMore}>
          <button className={styles.Close} onClick={handleClose}>
            <CloseIcon fontSize="large" color="secondary" />
          </button>
          <Box className={styles.SliderBackgroundAfter} sx={{ backgroundImage: `url(${imgSliderBackgroundAfter})` }}></Box>
          <Box className={styles.Number} ref={NumberRef}>
            <Box className={styles.NumberValue} ref={NumberValueRef}>
              {number < 10 ? `0${number}` : number}
            </Box>
            <Box className={styles.TitleMore}>{title}</Box>
          </Box>
          <Box className={styles.TitleWrapper} ref={TitleWrapperRef}>
            <Box className={styles.Title} ref={TitleRef}>
              {title}
            </Box>
          </Box>
          <Box className={styles.Content}>
            <Box className={[styles.ContentText, styles.TextScroll].join(" ")} sx={{ opacity: HasMore ? 1 : 0 }}>
              <Grid container spacing={1} sx={{ height: "100%" }}>
                <Grid item xs={12}>
                  <Box className={styles.BoxText}>{content}</Box>
                </Grid>
                {images === null || images.length === 0 ? null : (
                  <>
                    <Grid item xs={12}>
                      <ImageGallery
                        ref={refImg}
                        items={images}
                        showPlayButton={false}
                        showFullscreenButton={false}
                        useBrowserFullscreen={true}
                        slideOnThumbnailOver={true}
                        renderCustomControls={renderCustomControls}
                      ></ImageGallery>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
