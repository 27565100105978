import { Box } from "@mui/material";
import React from "react";
import styles from "./Form.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import Control from "./../UI/Control/Control";
import DoneIcon from "@mui/icons-material/Done";
import ButtonForm from "../UI/ButtonForm/ButtonForm";
import imgFormFeedbackBg1 from "../../img/FormFeedbackBg1.png";
import imgFormFeedbackBg2 from "../../img/FormFeedbackBg2.png";
import imgFormFeedbackBg3 from "../../img/FormFeedbackBg3.png";
import imgFormFeedbackButton1 from "../../img/FormFeedbackButton1.png";
import imgFormFeedbackButton2 from "../../img/FormFeedbackButton2.png";
import MessageHelper from "../../helper/MessageHelper";

export default function FormFeedback() {
  const [HasSubmit, setHasSubmit] = React.useState(false);
  const [Success, setSuccess] = React.useState(false);
  const [Error, setError] = React.useState(false);
  const [ButtonClick, setButtonClick] = React.useState(false);

  const handleReset = (e) => {
    setHasSubmit(false);
    setSuccess(false);
    setError(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setHasSubmit(true);
    var formData = new FormData(e.target);
    MessageHelper.Send(formData)
      .then((res) => {
        console.log(res);
        setSuccess(true);
        setError(false);
      })
      .catch((error) => {
        console.log(error);
        setSuccess(false);
        setError(true);
      });
  };
  let clsWrapper = [styles.Wrapper];
  if (ButtonClick) {
    clsWrapper.push(styles.WrapperToForm);
  }

  return (
    <Box className={clsWrapper.join(" ")}>
      <img src={imgFormFeedbackBg1} className={styles.ImgFormFeedbackBg1} alt="" />
      <img src={imgFormFeedbackBg2} className={styles.ImgFormFeedbackBg2} alt="" />
      <img src={imgFormFeedbackBg3} className={styles.ImgFormFeedbackBg3} alt="" />
      <Box className={styles.WrapperButton} sx={{ opacity: !ButtonClick ? "1" : "0", zIndex: !ButtonClick ? 1 : -1 }}>
        <Box className={styles.FormFeedbackButton} onClick={(e) => setButtonClick(!ButtonClick)}>
          <Box className={styles.FormFeedbackButtonImg1}>
            <img src={imgFormFeedbackButton1} alt="" />
          </Box>
          <Box className={styles.FormFeedbackButtonImg2}>
            <img src={imgFormFeedbackButton2} alt="" />
          </Box>
        </Box>
      </Box>
      <Box className={styles.WrapperForm} sx={{ opacity: ButtonClick ? "1" : "0", zIndex: ButtonClick ? 1 : -1 }}>
        <Box sx={{ position: "absolute", top: 0, right: -50, zIndex: 1 }} onClick={(e) => setButtonClick(false)}>
          <button className={styles.BtnClose}>
            <CloseIcon fontSize="large" color="primary" />
          </button>
        </Box>
        <Box className={styles.Container}>
          <Box component="form" action="#" className={styles.Form} onSubmit={handleSubmit} sx={{ mt: "5px", width: "365px" }}>
            <Box className={styles.FormStatus} style={{ zIndex: HasSubmit ? 1 : -1 }} onClick={handleReset}>
              <Box className={styles.FormStatusMessage} sx={{ opacity: Success && !Error ? 1 : 0 }}>
                <Box className={styles.FormStatusCircle} sx={{ borderColor: (theme) => theme.palette.primary.main }}>
                  <DoneIcon color="primary" fontSize="large" />
                  <Box component={"span"} sx={{ color: (theme) => theme.palette.primary.main }}>
                    Отправлено
                  </Box>
                </Box>
              </Box>
              <Box className={styles.FormStatusMessage} sx={{ opacity: !Success && Error ? 1 : 0 }}>
                <Box className={styles.FormStatusCircle} sx={{ borderColor: (theme) => theme.palette.error.main }}>
                  <DoneIcon color="error" fontSize="large" />
                  <Box component={"span"} sx={{ color: (theme) => theme.palette.error.main }}>
                    Ошибка
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={styles.FormBlur}></Box>
            <Box className={styles.FormContent} sx={{ opacity: HasSubmit ? 0 : 1 }}>
              <Control name="name" type="text" label="Имя:" styleContainer={{ mb: "24px" }} required />
              <Control name="company" type="text" label="Компания:" styleContainer={{ mb: "24px" }} required />
              <Control name="email" type="email" label="e-mail:" styleContainer={{ mb: "24px" }} required />
              <Control name="message" type="textarea" label="Введите сообщение:" styleContainer={{ mb: "24px" }} required />
              <Control type="checkbox" styleContainer={{ mb: "24px", pl: "5px" }} required>
                <span className={styles.CheckboxLabel}>
                  Я ознакомлен и согласен с<br />
                  <u>политикой конфиденциальности</u>
                </span>
              </Control>
              <Box className={styles.Actions}>
                <ButtonForm color={"primary"}>Отправить</ButtonForm>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
