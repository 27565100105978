import { Box } from "@mui/system";
import React from "react";
import styles from "./FormProject.module.scss";
import Control from "../UI/Control/Control";
import DoneIcon from "@mui/icons-material/Done";
import { Grid } from "@mui/material";
import ButtonForm from "../UI/ButtonForm/ButtonForm";
import MessageHelper from "../../helper/MessageHelper";

export default function FormProject() {
  const [HasSubmit, setHasSubmit] = React.useState(false);
  const [Success, setSuccess] = React.useState(false);
  const [Error, setError] = React.useState(false);

  const handleReset = (e) => {
    setHasSubmit(false);
    setSuccess(false);
    setError(false);
  };

  const handleSubmit = (e) => {
    console.log(1);
    e.preventDefault();
    setHasSubmit(true);
    var formData = new FormData(e.target);
    MessageHelper.Send(formData)
      .then((res) => {
        console.log(res);
        setSuccess(true);
        setError(false);
      })
      .catch((error) => {
        console.log(error);
        setSuccess(false);
        setError(true);
      });
  };

  return (
    <>
      <div className={styles.Container}>
        <Box component="form" action="#" className={styles.FormProject} onSubmit={handleSubmit}>
          <div className={styles.FormStatus} style={{ zIndex: HasSubmit ? 1 : -1 }} onClick={handleReset}>
            <Box className={styles.FormStatusMessage} sx={{ opacity: Success && !Error ? 1 : 0 }}>
              <Box className={styles.FormStatusCircle} sx={{ borderColor: (theme) => theme.palette.light.main }}>
                <DoneIcon color="white" fontSize="large" />
                <Box component={"span"} sx={{ color: (theme) => theme.palette.light.main }}>
                  Отправлено
                </Box>
              </Box>
            </Box>
            <Box className={styles.FormStatusMessage} sx={{ opacity: !Success && Error ? 1 : 0 }}>
              <Box className={styles.FormStatusCircle} sx={{ borderColor: (theme) => theme.palette.error.main }}>
                <DoneIcon color="error" fontSize="large" />
                <Box component={"span"} sx={{ color: (theme) => theme.palette.error.main }}>
                  Ошибка
                </Box>
              </Box>
            </Box>
          </div>
          <Box className={styles.FormContent} sx={{ opacity: HasSubmit ? 0 : 1 }}>
            <Grid container>
              <Grid item xs={12}>
                <Box component={"h4"} className={styles.Title} sx={{ mb: "70px" }}>
                  Обсудить{" "}
                  <Box component="span" sx={{ color: (theme) => theme.palette.secondary.main }}>
                    проект
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Control name="name" type="text" label="Имя:" styleContainer={{ mb: "24px" }} styleInput={{ maxWidth: "380px" }} required />
                <Control name="company" type="text" label="Компания:" styleContainer={{ mb: "24px" }} styleInput={{ maxWidth: "380px" }} required />
                <Control name="email" type="email" label="e-mail:" styleContainer={{ mb: "24px" }} styleInput={{ maxWidth: "380px" }} required />
              </Grid>
              <Grid item xs={12} lg={6} style={{ display: "flex", flexDirection: "column" }}>
                <Control name="message" type="textarea" label="Введите сообщение:" styleContainer={{ flexGrow: "1", mb: "24px" }} required />
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item lg={6}></Grid>

              <Grid item lg={6}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ display: "flex", flexGrow: 1, alignItems: "center" }}>
                    <Control type="checkbox" styleContainer={{ display: "flex", flexGrow: 1, alignItems: "center" }} required>
                      <span className={styles.CheckboxLabel} style={{ color: "white" }}>
                        Я ознакомлен и согласен с<br />
                        <u>политикой конфиденциальности</u>
                      </span>
                    </Control>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "end", flexGrow: 1 }}>
                    <ButtonForm color="white">Отправить</ButtonForm>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </div>
    </>
  );
}
