import { Box } from "@mui/system";
import React from "react";
import styles from "./Section.module.scss";

export default function Section({ children, title, sx, styleContent, id, isHiddenLine }) {
  let clsSection = [styles.Section];
  let clsTitle = [styles.Title];
  if (!!isHiddenLine) {
    clsSection.push(styles.HiddenLine);
    clsTitle.push(styles.HiddenLine);
  }

  return (
    <Box id={id} className={clsSection.join(" ")}>
      <Box className={clsTitle.join(" ")}>
        <h2>{title}</h2>
      </Box>
      <Box className={styles.Content} sx={styleContent}>
        <Box sx={sx}>{children}</Box>
      </Box>
    </Box>
  );
}
