import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  activeSlide: -1,
  containerPositionX: 0,
  sliderPositionRight: 0,
};

const SliderSlice = createSlice({
  name: "slider",
  initialState,
  reducers: {
    setAll(state, action) {
      state.activeSlide = action.payload.activeSlide;
      state.containerPositionX = action.payload.containerPositionX;
      state.sliderPositionRight = action.payload.sliderPositionRight;
    },
    setActiveSlide(state, action) {
      state.activeSlide = action.payload;
    },
    setContainerPositionX(state, action) {
      state.containerPositionX = action.payload;
    },
    setSliderPositionRight(state, action) {
      state.sliderPositionRight = action.payload;
    },
  },
});

export const { setAll, setActiveSlide, setContainerPositionX, setSliderPositionRight, setRestart } = SliderSlice.actions;
export default SliderSlice.reducer;
