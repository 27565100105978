import React from "react";
import styles from "./Section.module.scss";
import { Grid, Container } from "@mui/material";
import { Box } from "@mui/system";
import Slider from "./../component/Slider/Slider";
import { isMobile } from "react-device-detect";
import SliderMobile from "../component/Slider/SliderMobile";

const idSection = "DiscussProject";

export default function ProjectsSection() {
  const wrapperRef = React.useRef();

  return (
    <Box id={idSection} className={styles.ProjectsSectionWrapper} sx={{ bgcolor: (theme) => theme.palette.primary.dark }} ref={wrapperRef}>
      <Box className={styles.ProjectsSectionPatternLeft}></Box>
      <Box className={styles.ProjectsSectionPatternRight}></Box>
      <Container maxWidth="lg" sx={{ height: "100%" }}>
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <Box sx={{ mt: "48px", mb: "50px", "@media (max-height: 800px)": { mt: "0", mb: "20px" } }}>
            <Box sx={{ typography: "Display1", color: (theme) => theme.palette.light.main }}>
              Наши{" "}
              <Box component="span" sx={{ color: (theme) => theme.palette.secondary.main }}>
                проекты
              </Box>
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1 }}>{!isMobile ? <Slider wrapperRef={wrapperRef} /> : <SliderMobile sx={{ mb: "30px" }} wrapperRef={wrapperRef} />}</Box>
        </Box>
      </Container>
    </Box>
  );
}
