export default class Helper {
  /**
   * Получить HEADER для запроса
   * @returns
   */
  static GetHeader() {
    let token = localStorage.getItem("token");
    return {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    };
  }

  /**
   * Получить OPTIONS для axios
   * @returns
   */
  static GetOptions() {
    return {
      headers: Helper.GetHeader(),
    };
  }

  static GetServerName() {
    return !process.env.NODE_ENV || process.env.NODE_ENV === "development" ? "http://dtlab.site:8080" : "";
  }
}
