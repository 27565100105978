import React from "react";
import styles from "./Section.module.scss";
import { Grid, Container } from "@mui/material";
import Section from "../hoc/Section/Section";
import { Box } from "@mui/system";
import Map from "../component/Map/Map";

const idSection = "ContactSection";
export default function ContactSection() {
  return (
    <Container maxWidth="lg">
      <Section id={idSection} title={"КОНТАКТЫ"}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box className={styles.ContactSectionText}>
              <Box sx={{ typography: "Display1", mt: "30px" }}>
                Будем рады
                <br />
                <Box component="span" sx={{ color: (theme) => theme.palette.primary.main }}>
                  сотрудничеству!
                </Box>{" "}
              </Box>
              <Box sx={{ typography: "body1", mt: "45px" }}>
                <Box sx={{ typography: "body1", mb: "30px" }}>
                  <b>Звоните по номеру:</b>
                  <br />
                  (8412) 28-00-46
                </Box>
                <Box sx={{ typography: "body1", mb: "30px" }}>
                  <b>Мы находимся по адресу:</b>
                  <br />
                  г. Заречный, Пензенской обл.,
                  <br />
                  пр-д Индустриальный, строение 6, ком. 205
                </Box>
                <Box sx={{ typography: "body1", mb: "30px" }}>
                  <b>Электронная почта:</b>
                  <br />
                  info@dtlab.teсh
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Map />
          </Grid>
        </Grid>
      </Section>
    </Container>
  );
}
