import { Box } from "@mui/system";
import React from "react";
import styles from "./Map.module.scss";
import imgMap from "../../img/map.png";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import Iframe from "react-iframe";
import IconButton from "@mui/material/IconButton";
import { Typography } from "@mui/material";

export default function Map() {
  const [Open, setOpen] = React.useState(false);

  return (
    <>
      <Box className={styles.Map}>
        <img src={imgMap} alt="" />
        <Box className={styles.MapCircle} onClick={(e) => setOpen(true)}>
          <Box className={styles.MapCircle1}>
            <Box className={styles.MapCircle2}>
              <Box className={styles.MapCircle3}></Box>
            </Box>
          </Box>
          <Box className={styles.MapCircleText}>
            <Box sx={{ fontSize: "14px" }}>Zarechny</Box>
            <Box sx={{ fontSize: "18px" }}>Заречный</Box>
          </Box>
        </Box>
      </Box>

      <Dialog open={Open} onClose={(e) => setOpen(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth="lg" fullWidth>
        <IconButton
          aria-label="close"
          onClick={(e) => setOpen(false)}
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            width: "auto",
            height: "auto",
            padding: "20px",
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ typography: "Display3", m: "30px 10px 20px 10px", color: (theme) => theme.palette.primary.main }}>Мы находимся здесь</Box>

        <DialogContent sx={{ padding: 0, overflow: "hidden" }}>
          <Iframe
            url="https://yandex.ru/map-widget/v1/?um=constructor%3A3980fe1310d66c14010539a8841d81cb7e9d7a0c2b5d7d932df3f58f2167fd94&amp;source=constructor"
            width="100%"
            height="500"
            display="initial"
            frameBorder={0}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
