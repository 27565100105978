import React from "react";
import styles from "./Slider.module.scss";
import { Box } from "@mui/system";
import { v4 as uuidv4 } from "uuid";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useDispatch, useSelector } from "react-redux";
import { setAll, setSliderPositionRight } from "../../redux/slices/SliderSlice";
import SliderItem from "./SliderItem";
import ProjectHelper from "../../helper/ProjectHelper";
import FileHelper from "./../../helper/FileHelper";

gsap.registerPlugin(ScrollTrigger);
const id = uuidv4();

export default function Slider({ wrapperRef }) {
  const dispatch = useDispatch();

  // ref
  const SliderRef = React.useRef();
  const containerRef = React.useRef();

  // const
  const [Items, setItems] = React.useState([]);

  // При загрузке страницы
  React.useEffect(() => {
    ProjectHelper.GetAll()
      .then((res) => {
        setItems(res);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        //
      });
  }, []);

  // При изменение слайдов
  React.useEffect(() => {
    if (Items === null || Items.length === 0) {
      return;
    }
    let sliderPositionRight = SliderRef.current.offsetWidth + SliderRef.current.getBoundingClientRect().left;
    dispatch(setSliderPositionRight(sliderPositionRight));
    let widthFirstSlide = containerRef.current.firstChild.offsetWidth;
    let countSlide = containerRef.current.childNodes.length;
    let widthTranslateX = (widthFirstSlide + 100) * (countSlide - 3) * -1;

    gsap.to(containerRef.current, {
      scrollTrigger: {
        trigger: wrapperRef.current,
        start: "top",
        end: "bottom",
        scrub: 1,
        scrub: true,
        pin: true,
        markers: !process.env.NODE_ENV || process.env.NODE_ENV === "development",
        toggleActions: "restart pause reverse pause",
        onLeaveBack: function () {
          //
        },
        onUpdate: function () {
          let payload = {
            activeSlide: -1,
            containerPositionX: containerRef.current.getBoundingClientRect().left,
            sliderPositionRight: SliderRef.current.offsetWidth + SliderRef.current.getBoundingClientRect().left,
          };
          dispatch(setAll(payload));
        },
      },
      duration: 25,
      translateX: widthTranslateX,
    });
  }, [Items]);

  if (Items.length == 0) {
    return null;
  }

  return (
    <Box id={id} className={styles.Container} ref={SliderRef} sx={{ mb: "30px", height: "100%" }}>
      <Box className={styles.Slider} sx={{ height: "100%" }}>
        <Box className={styles.SliderContainer} ref={containerRef}>
          {Items.map((item) => {
            return (
              <SliderItem
                key={item.id}
                number={item.number}
                title={item.title}
                sliderRef={SliderRef}
                img={item.img}
                containerRef={containerRef}
                desc={item.desc}
                content={item.content}
                galleryFiles={item.projectFiles}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}
