import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { createBrowserHistory } from "history";

const history = createBrowserHistory({ window });
const root = ReactDOM.createRoot(document.getElementById('root'));
const strictMode = process.env.NODE_ENV === 'production';

root.render(
  (strictMode && (
    <React.StrictMode>
      <React.Fragment>
        <Provider store={store}>
          <React.StrictMode>
            <HistoryRouter history={history}>
              <App />
            </HistoryRouter>
          </React.StrictMode>
        </Provider>
      </React.Fragment>
    </React.StrictMode>
  )) || <React.Fragment>
    <Provider store={store}>
      <React.StrictMode>
        <HistoryRouter history={history}>
          <App />
        </HistoryRouter>
      </React.StrictMode>
    </Provider>
  </React.Fragment>,
);


reportWebVitals();
