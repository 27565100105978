import { Box } from "@mui/system";
import React from "react";
import styles from "./Control.module.scss";

import { v4 as uuidv4 } from "uuid";

export default function Control({ type, styleContainer, styleInput, name, placeholder, required, children, label }) {
  type = !!type ? type : "text";

  const ControlText = (e) => {
    const [Value, setValue] = React.useState("");
    const [HasFocus, setHasFocus] = React.useState(false);
    let id = uuidv4();

    let clsLabel = [styles.Label];
    if ((!!Value && Value.length > 0) || HasFocus) {
      clsLabel.push(styles.LabelFocus);
    }
    return (
      <Box className={styles.Container} sx={styleContainer}>
        <input
          id={id}
          type={"type"}
          className={styles.InputText}
          style={!!styleInput ? styleInput : null}
          name={!!name ? name : null}
          required={!!required}
          defaultValue={Value}
          onChange={(e) => setValue(e.target.value)}
          onFocus={(e) => setHasFocus(true)}
          onBlur={(e) => setHasFocus(false)}
        />
        {!!label ? (
          <>
            <label htmlFor={id} className={clsLabel.join(" ")}>
              {label}
            </label>
          </>
        ) : null}
      </Box>
    );
  };

  const ControlTextarea = (e) => {
    const [Value, setValue] = React.useState("");
    const [HasFocus, setHasFocus] = React.useState(false);
    let id = uuidv4();

    let clsLabel = [styles.Label];
    if ((!!Value && Value.length > 0) || HasFocus) {
      clsLabel.push(styles.LabelFocus);
    }
    return (
      <Box className={styles.Container} sx={styleContainer}>
        <textarea
          id={id}
          className={styles.InputTextarea}
          style={!!styleInput ? styleInput : null}
          name={!!name ? name : null}
          placeholder={!!placeholder ? placeholder : ""}
          required={!!required}
          defaultValue={Value}
          onChange={(e) => setValue(e.target.value)}
          onFocus={(e) => setHasFocus(true)}
          onBlur={(e) => setHasFocus(false)}
        ></textarea>
        {!!label ? (
          <label htmlFor={id} className={clsLabel.join(" ")}>
            {label}
          </label>
        ) : null}
      </Box>
    );
  };

  const ControlCheckbox = (e) => {
    let id = uuidv4();

    return (
      <Box className={styles.ContainerCheckbox} sx={styleContainer}>
        <input type="checkbox" className={styles.Checkbox} id={id} required={required} />
        <label htmlFor={id} className={styles.CheckboxLabel}>
          {children}
        </label>
      </Box>
    );
  };

  if (type === "text" || type === "email") {
    return <ControlText />;
  }

  if (type === "textarea") {
    return <ControlTextarea />;
  }

  if (type === "checkbox") {
    return <ControlCheckbox />;
  }
}
