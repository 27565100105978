import { grey, yellow } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";
import { ruRU } from "@mui/material/locale";

export const lightTheme = createTheme(
  {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1280,
        xl: 1536,
      },
    },
    palette: {
      mode: "light",
      primary: {
        light: "#3300cc",
        main: "#3300cc",
        dark: "#281475",
        contrastText: "#ffffff",
      },
      grey: {
        light: "#65606a",
        main: "#65606a",
        dark: "#65606a",
        contrastText: "#ffffff",
      },
      bg: {
        main: grey[300],
      },
      light: {
        main: grey[100],
      },
      secondary: {
        light: "#cbff36",
        main: "#cbff36",
        dark: "#cbff36",
        contrastText: "#281475",
      },
      warning: {
        main: yellow[600],
      },
    },
    typography: {
      fontFamily: "Raleway",
      Display1: {
        fontSize: "48px",
        fontWeight: "bold",
        letterSpacing: "1.3px",
        lineHeight: "1.458",
        marginTop: "30px",
        "@media (max-width: 800px)": { fontSize: "36px" },
      },
      Display3: {
        fontSize: "24px",
        fontWeight: "bold",
        letterSpacing: "1.3px",
        lineHeight: "1.458",
        marginTop: "30px",
        "@media (max-width: 800px)": { fontSize: "36px" },
      },
      body1: {
        fontSize: "18px",
        color: "#65606a",
        lineHeight: 1.667,
      },
    },
  },
  ruRU
);
