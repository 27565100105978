import React from "react";
import styles from "./Header.module.scss";
import imgLogo from "../../img/logo.png";
import Button from "./../UI/Button/Button";
import { Box, Container } from "@mui/system";
import { Link } from "react-scroll";

export default function Header() {
  let clsMenu = [styles.Menu, styles.ForDesktop];
  return (
    <Container maxWidth="lg" className={styles.Container}>
      <Box component="div" className={styles.Header}>
        <Box className={styles.Logo}>
          <img src={imgLogo} alt="ООО ПКФ ПОЛЕТ" />
        </Box>
        <Box className={styles.Title}>
          <h1 className={styles.ForDesktop}>ЛАБОРАТОРИЯ ЦИФРОВОГО ТРАНСПОРТА</h1>
          <h1 className={styles.ForMobile}>ЛЦТ</h1>
        </Box>
        <div className={clsMenu.join(" ")}>
          <div className={styles.MenuItem}>
            <Link to="AboutSection" spy={true} smooth={true} duration={500}>
              <Button>О нас</Button>
            </Link>
          </div>
          <div className={styles.MenuItem}>
            <Link to="DiscussProject" spy={true} smooth={true} duration={500}>
              <Button>Проекты</Button>
            </Link>
          </div>
          <div className={styles.MenuItem}>
            <Link to="ContactSection" spy={true} smooth={true} duration={500}>
              <Button>Контакты</Button>
            </Link>
          </div>
        </div>
      </Box>
    </Container>
  );
}
