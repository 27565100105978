import { Box } from "@mui/system";
import React from "react";
import styles from "./ButtonForm.module.scss";

export default function ButtonForm({ children, color, variant, onClick, sx }) {
  let cls = [styles.ButtonForm];

  if (!!color && color === "white") {
    cls.push(styles.White);
  }

  if (!!color && color === "primary") {
    cls.push(styles.Primary);
  }

  return (
    <Box component="button" className={cls.join(" ")} onClick={onClick} sx={sx}>
      {children}
    </Box>
  );
}
