import { Box } from "@mui/system";
import React from "react";
import ButtonForm from "../UI/ButtonForm/ButtonForm";
import styles from "./Slider.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import { setActiveSlide } from "../../redux/slices/SliderSlice";
import { useDispatch, useSelector } from "react-redux";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { v4 as uuidv4 } from "uuid";
import imgSliderBackPattern from "../../img/SliderBackPattern.png";
import imgSliderBackgroundAfter from "../../img/slider_item_bg.png";
import SliderContent from "./SliderContent";
import { useNavigate } from "react-router-dom";

gsap.registerPlugin(ScrollTrigger);
const id = uuidv4();

export default function SliderItem({ number, title, img, desc, content, galleryFiles }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const ActiveSlide = useSelector((state) => state.slider.activeSlide);

  const itemRef = React.useRef(null);
  const NumberRef = React.useRef(null);
  const NumberValueRef = React.useRef(null);
  const NumberParent1Ref = React.useRef(null);
  const NumberParent2Ref = React.useRef(null);
  const TitleWrapperRef = React.useRef(null);
  const TitleRef = React.useRef(null);
  const ActionsRef = React.useRef(null);

  const [HasMore, setHasMore] = React.useState(false);
  const [WidthSlider, setWidthSlider] = React.useState(0);
  const [WidthSliderItem, setWidthSliderItem] = React.useState(0);
  const [SaveTransform, setSaveTransform] = React.useState("");

  React.useEffect(() => {
    setWidthSlider(itemRef.current.parentElement.clientWidth);
    setWidthSliderItem(itemRef.current.clientWidth);
  }, [itemRef]);

  React.useEffect(() => {
    if (ActiveSlide !== number) {
      handleClose();
    }
  }, [ActiveSlide]);

  /**
   * Открыть расширенный просмотр проекта
   * @param {*} e
   * @returns
   */
  const handleMore = (e) => {
    if (HasMore) return;
    setHasMore(true);
    dispatch(setActiveSlide(number));
    gsap.to(itemRef.current, {
      width: WidthSlider,
      minWidth: `${WidthSlider}px`,
    });

    let width = (itemRef.current.offsetWidth + 60) * (number - 1);

    let saveTransform = itemRef.current.parentElement.style.transform;
    setSaveTransform(saveTransform);

    gsap.to(itemRef.current.parentElement, {
      translateX: `-${width - 10}`,
    });
  };

  /**
   * Закрыть расширенный просмотр проекта
   * @param {*} e
   */
  const handleClose = () => {
    setHasMore(false);

    gsap.to(itemRef.current, {
      width: WidthSlider,
    });

    if (WidthSliderItem > 0) {
      gsap.to(itemRef.current, {
        minWidth: `${WidthSliderItem}px`,
      });
    }

    if (ActiveSlide === number) {
      dispatch(setActiveSlide(-1));
      let x = 0;
      if (SaveTransform.indexOf("translate(") > -1) {
        let to = SaveTransform.search("\\)");
        let str = SaveTransform.substring(10, to);
        let arr = str.split(",");
        if (arr.length > 0) {
          let parseX = arr[0].substring(0, arr[0].search("\\px"));
          x = parseInt(parseX);
        }
      }
      if (SaveTransform.indexOf("translate3d(") > -1) {
        let to = SaveTransform.search("\\)");
        let str = SaveTransform.substring(12, to);
        let arr = str.split(",");
        if (arr.length > 0) {
          let parseX = arr[0].substring(0, arr[0].search("\\px"));
          x = parseInt(parseX);
        }
      }
      gsap.to(itemRef.current.parentElement, {
        translateX: x,
      });
    }
  };

  const handleMouseOver = (e) => {
    console.log(e);
  };

  let clsSlide = [styles.SliderItem];
  if (HasMore) {
    clsSlide.push(styles.SliderItemMore);
  }

  let clsSliderItemWrapper = [styles.SliderItemWrapper];
  if (HasMore) {
    clsSliderItemWrapper.push(styles.SliderItemWrapperMore);
  }

  return (
    <Box className={clsSliderItemWrapper.join(" ")} ref={itemRef}>
      <Box className={styles.SliderBackPattern} onMouseOver={handleMouseOver}>
        <img src={imgSliderBackPattern} alt="" />
      </Box>
      <Box id={id} className={clsSlide.join(" ")}>
        <button className={styles.Close} onClick={handleClose}>
          <CloseIcon fontSize="large" color="secondary" />
        </button>
        <Box className={styles.SliderBackground} sx={{ backgroundImage: `url(${img})` }}></Box>
        <Box className={styles.SliderBackgroundAfter} sx={{ backgroundImage: `url(${imgSliderBackgroundAfter})` }}></Box>
        <Box className={styles.Number} ref={NumberRef}>
          <Box className={styles.NumberParent2} ref={NumberParent1Ref}></Box>
          <Box className={styles.NumberParent1} ref={NumberParent2Ref}></Box>
          <Box className={styles.NumberValue} ref={NumberValueRef}>
            {number < 10 ? `0${number}` : number}
          </Box>
          <Box className={styles.TitleMore}>{title}</Box>
        </Box>
        <Box className={styles.TitleWrapper} ref={TitleWrapperRef}>
          <Box className={styles.Title} ref={TitleRef}>
            {title}
          </Box>
        </Box>
        <SliderContent HasMore={HasMore} content={content} desc={desc} galleryFiles={galleryFiles} />
        <Box className={styles.Actions} ref={ActionsRef}>
          <ButtonForm
            sx={{ minWidth: 150 }}
            onClick={(e) => {
              if (HasMore) {
                navigate("");
                handleClose();
              } else {
                handleMore(e);
              }
            }}
          >
            {HasMore ? "Закрыть" : "Подробнее"}
          </ButtonForm>
        </Box>
      </Box>
    </Box>
  );
}
