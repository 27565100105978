import React from "react";
import styles from "./Footer.module.scss";
import imgLogo from "../../img/logo.png";
import { Box, color, Container } from "@mui/system";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function Footer() {
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = React.useState(false);
  const [openUserAgreement, setOpenUserAgreement] = React.useState(false);

  return (
    <>
      <Container maxWidth="lg" className={styles.Container}>
        <Box className={styles.Footer}>
          <Box className={styles.Logo}>
            <img src={imgLogo} alt="ООО ПКФ ПОЛЕТ" />
          </Box>
          <Box className={styles.Content}>
            <Grid container spacing={1}>
              <Grid item xs={4} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {`© ${new Date().getFullYear()} ООО «ЛЦТ»`}.
              </Grid>
              <Grid item xs={4} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Button
                  variant="text"
                  onClick={(e) => {
                    setOpenPrivacyPolicy(true);
                  }}
                  sx={{ color: "#000000" }}
                >
                  Политика конфиденциальности
                </Button>
              </Grid>
              <Grid item xs={4} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Button
                  variant="text"
                  onClick={(e) => {
                    setOpenUserAgreement(true);
                  }}
                  sx={{ color: "#000000" }}
                >
                  Пользовательское соглашение
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>

      <Dialog
        open={openPrivacyPolicy}
        onClose={(e) => {
          setOpenPrivacyPolicy(false);
        }}
        maxWidth="lg"
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Политика конфиденциальности</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>
              <b>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</b>
            </p>
            <p>
              Настоящая Политика конфиденциальности персональных данных (далее – Политика конфиденциальности) действует в отношении всей информации, которую Общество с ограниченной
              ответственностью Промышленно-коммерческая фирма «Полёт» (сокращённо - ООО ПКФ «Полёт») (далее — Оператор), обладающее в информационно-телекоммуникационной сети Интернет сайтом
              с доменным именем www.dtlab.tech, может получить о Пользователе во время использования сайта Оператора.
            </p>
            <p>
              Для обеспечения использования Вами Сайта Оператора Персональная информация собирается, обрабатывается и используется Обществом с ограниченной ответственностью
              Промышленно-коммерческая фирма «Полёт» (сокращённо - ООО ПКФ «Полёт») ИНН 5838040160, ОГРН 1025801497303, юридический адрес: 442961, Российская Федерация, Пензенская область,
              город Заречный (ЗАТО), улица Фадеева, дом 60.
            </p>
            <p>
              <b>1. ОПРЕДЕЛЕНИЕ ТЕРМИНОВ</b>
            </p>
            <p>
              1.1. В настоящей Политике конфиденциальности используются следующие термины: 1.1.1. Сайт – официальный сайт Оператора в сети Интернет, расположенный по адресам www.dtlab.tech.
              1.1.2. «Администрация Сайта» – Оператор в лице его уполномоченных работников, которые организуют и (или) осуществляют обработку персональных данных, а также определяют цели
              обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными. 1.1.3. «Персональные данные»,
              «персональная информация» — любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу (субъекту персональных данных). 1.1.4.
              «Обработка персональных данных» — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких
              средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу
              (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных. 1.1.5. «Конфиденциальность персональных данных» —
              обязательное для соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных
              данных или наличия иного законного основания. 1.1.6. «Пользователь Сайта» (далее — Пользователь) – лицо, имеющее доступ к Сайту, посредством сети Интернет и использующее Сайт
              Оператора. 1.1.7. «Cookies» — текстовые файлы, создаваемые веб-сервером Сайта и записываемые в браузер Пользователя при первом посещении Сайта с целью автоматической
              идентификации Пользователя при последующих посещениях Сайта. Cookies-файлы позволяют Администрации Сайта – отслеживать статистику посещения Сайта новыми уникальными
              пользователями. 1.1.8. «IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.
            </p>
            <p>
              <b>2. ОБЩИЕ ПОЛОЖЕНИЯ</b>
            </p>
            <p>
              2.1. Использование Пользователем Сайта означает согласие с настоящей Политикой конфиденциальности и условиями обработки персональных данных Пользователя. 2.2. В случае
              несогласия с условиями Политики конфиденциальности Пользователь должен прекратить использование Сайта. 2.3. Настоящая Политика конфиденциальности применяется только к сайту
              Общества с ограниченной ответственностью Промышленно-коммерческая фирма «Полёт» www.dtlab.tech. Оператор не контролирует и не несет ответственность за сайты третьих лиц, на
              которые Пользователь может перейти по ссылкам, доступным на сайте Оператора. 2.4. Администрация Сайта не проверяет достоверность персональных данных, предоставляемых
              Пользователем сайта Оператора.
            </p>
            <p>
              <b>3. ПРЕДМЕТ ПОЛИТИКИ КОНФИДЕНЦИАЛЬНОСТИ</b>
            </p>
            <p>
              3.1. Настоящая Политика конфиденциальности устанавливает обязательства Администрации Сайта по неразглашению и обеспечению режима защиты конфиденциальности персональных данных,
              которые Пользователь предоставляет по запросу Администрации сайта при заполнении любой формы обратной связи на Сайте . 3.2. Принятие условий настоящей Политики в части
              положений об обработке персональных данных, осуществляется путем проставления Пользователем соответствующей отметки при заполнении любой формы обратной связи на сайте Оператора
              и является выраженным осознанным согласием Пользователя на обработку персональных данных. Данные Пользователя включают в себя следующую информацию: 3.2.1. фамилию, имя,
              отчество Пользователя; 3.2.2. контактный телефон Пользователя; 3.2.3. адрес электронной почты (e-mail) Пользователя. 3.3. Оператор защищает Данные, которые автоматически
              передаются при посещении страниц. 3.4. Оператор осуществляет сбор электронных данных: HTTP-заголовки, IP-адрес, файлы cookie, веб-маяки/пиксельные теги, данные об
              идентификаторе браузера, информацию об аппаратном и программном обеспечении. Данная информация используется с целью выявления и решения возможных технических проблем. 3.5.
              Любая иная персональная информация неоговоренная выше (используемые браузеры и операционные системы и т.д.) подлежит надежному хранению и нераспространению, за исключением
              случаев, предусмотренных в п.п. 5.2. и 5.3. настоящей Политики конфиденциальности.
            </p>
            <p>
              <b>4. ЦЕЛИ СБОРА ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЯ</b>
            </p>
            <p>
              4.1. Персональные данные Пользователя Администрация сайта Оператора может использовать в целях: 4.1.1. Установления с Пользователем обратной связи, включая направление
              уведомлений, запросов, касающихся использования Сайта, оказание услуг, обработку запросов и заявок от Пользователя. 4.1.2. Подтверждения достоверности и полноты персональных
              данных, предоставленных Пользователем. 4.1.3. Предоставления Пользователю эффективной клиентской и технической поддержки при возникновении проблем, связанных с использованием
              Сайта. 4.1.4. Предоставления Пользователю с его согласия, обновлений продукции, специальных предложений, информации о ценах, новостной рассылки и иных сведений от имени
              Оператора. 4.2. В соответствии с Федеральным законом от 27.07.2006г. №152-ФЗ «О персональных данных» Пользователь соглашается разместить свои персональные данные, отправив на
              адрес электронной почты Оператора: info@dtlab.tech в виде электронного документа - резюме, необходимое для эффективного трудоустройства, а также выражает (предоставляет) свое
              согласие Оператору на осуществление со всеми указанными в соответствующем резюме персональными данными (а также с самим резюме) следующих действий: сбор, систематизацию,
              накопление, хранение, уточнение (обновление или изменение), использование, распространение, обезличивание, блокирование, уничтожение, а также обработку для целей исполнения
              Оператором своих обязательств по целям, указанным в разделе 4 настоящей Политики, путем установления (включая направления сообщений) с помощью средств связи по контактной
              информации, указанной в резюме. Настоящее согласие действует до истечения сроков хранения соответствующей информации или документов, содержащих персональные данные
              Пользователя, устанавливаемых в соответствии с законодательством РФ, и может быть отозвано Пользователем на основании письменного заявления. Порядок отзыва согласия на
              обработку персональных данных Пользователя: для отзыва согласия на обработку персональных данных Пользователю необходимо подать соответствующее заявление в письменной форме по
              месту нахождения Оператора как юридического лица, либо направить отзыв заказным письмом с уведомлением на адрес места нахождения юридического лица, не менее чем за 30 дней до
              момента отзыва соответствующего согласия. Возможно направление в форме электронного документа по адресу электронной почты: info@dtlab.tech. Запрос должен содержать следующую
              информацию: 1) номер основного документа, удостоверяющего личность Пользователя; 2) сведения о дате выдачи указанного документа и выдавшем его органе; 3) сведения,
              подтверждающие участие Пользователя в отношениях с Оператором; 4) подпись Пользователя. После отзыва согласия персональные данные Пользователя используются только в целях,
              предусмотренных законодательством.
            </p>
            <p>
              <b>5. СПОСОБЫ И СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНОЙ ИНФОРМАЦИИ</b>
            </p>
            <p>
              5.1. Обработка персональных данных Пользователя осуществляется без ограничения срока, любым законным способом, в том числе в информационных системах персональных данных с
              использованием средств автоматизации или без использования таких средств. 5.2. Персональные данные Пользователя могут быть переданы уполномоченным органам государственной
              власти Российской Федерации только по основаниям и в порядке, установленным законодательством Российской Федерации. 5.3. При утрате или разглашении персональных данных
              Администрация сайта информирует Пользователя об утрате или разглашении персональных данных. 5.4. Администрация сайта принимает необходимые организационные и технические меры
              для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных
              неправомерных действий третьих лиц. 5.5. Администрация сайта совместно с Пользователем принимает все необходимые меры по предотвращению убытков или иных отрицательных
              последствий, вызванных утратой или разглашением персональных данных Пользователя.
            </p>
            <p>
              <b>6. ОБЯЗАТЕЛЬСТВА СТОРОН</b>
            </p>
            <p>
              6.1. Пользователь обязан: 6.1.1. Предоставить информацию о персональных данных, необходимую для пользования Сайтом. 6.1.2. Обновить, дополнить предоставленную информацию о
              персональных данных в случае изменения данной информации. 6.2. Администрация сайта обязана: 6.2.1. Использовать полученную информацию исключительно для целей, указанных в п. 4
              настоящей Политики конфиденциальности. 6.2.2. Обеспечить хранение конфиденциальной информации в тайне, не разглашать без предварительного письменного разрешения Пользователя, а
              также не осуществлять продажу, обмен, опубликование, либо разглашение иными возможными способами переданных персональных данных Пользователя, за исключением п.п. 5.2. и 5.3.
              настоящей Политики Конфиденциальности. 6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных данных Пользователя согласно порядку, обычно
              используемому для защиты такого рода информации в существующем деловом обороте. 6.2.4. Осуществить блокирование персональных данных, относящихся к соответствующему
              Пользователю, с момента обращения или запроса Пользователя или его законного представителя либо уполномоченного органа по защите прав субъектов персональных данных на период
              проверки, в случае выявления недостоверных персональных данных или неправомерных действий.
            </p>
            <p>
              <b>7. ОТВЕТСТВЕННОСТЬ СТОРОН</b>
            </p>
            <p>
              7.1. Администрация сайта, не исполнившая свои обязательства, несёт ответственность за убытки, понесённые Пользователем в связи с неправомерным использованием персональных
              данных, в соответствии с законодательством Российской Федерации, за исключением случаев, предусмотренных п.п. 5.2., 5.3. и 7.2. настоящей Политики Конфиденциальности. 7.2. В
              случае утраты или разглашения Конфиденциальной информации Администрация сайта не несёт ответственность, если данная конфиденциальная информация: 7.2.1. Стала публичным
              достоянием до её утраты или разглашения. 7.2.2. Была получена от третьей стороны до момента её получения Администрацией сайта. 7.2.3. Была разглашена с согласия Пользователя.
            </p>
            <p>
              <b>8. РАЗРЕШЕНИЕ СПОРОВ</b>
            </p>
            <p>
              8.1. До обращения в суд с иском по спорам, возникающим из отношений между Пользователем сайта и Администрацией сайта, обязательным является предъявление претензии (письменного
              предложения о добровольном урегулировании спора). 8.2. Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно уведомляет заявителя претензии о
              результатах рассмотрения претензии. 8.3. При не достижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с действующим законодательством
              Российской Федерации. 8.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и Администрацией сайта применяется действующее законодательство Российской
              Федерации.
            </p>
            <p>
              <b>9. ДОПОЛНИТЕЛЬНЫЕ УСЛОВИЯ</b>
            </p>
            <p>
              9.1. Администрация сайта вправе вносить изменения в настоящую Политику конфиденциальности без согласия Пользователя. 9.2. Новая Политика конфиденциальности вступает в силу с
              момента её размещения на Сайте Оператора, если иное не предусмотрено новой редакцией Политики конфиденциальности. 9.3. Все предложения или вопросы по настоящей Политике
              конфиденциальности следует сообщать по номеру телефона или адресу электронной почты, указанным на сайте Оператора. 9.4. Действующая Политика конфиденциальности размещена на
              странице по адресу: www.dtlab.tech. 9.5. В настоящую Политику конфиденциальности могут быть внесены изменения. Оператор обязуется уведомлять Пользователя обо всех изменениях,
              которые касаются изменения режима сбора, обработки и хранения Персональной информации, а также расширения категорий собираемой и обрабатываемой Оператором информации путем
              размещения Политики конфиденциальности в новой редакции на Сайте.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              setOpenPrivacyPolicy(false);
            }}
            autoFocus
          >
            Назад
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openUserAgreement}
        onClose={(e) => {
          setOpenUserAgreement(false);
        }}
        maxWidth="lg"
        fullWidth={true}
        aria-labelledby="alert-dialog-title2"
        aria-describedby="alert-dialog-description2"
      >
        <DialogTitle id="alert-dialog-title2">Пользовательское соглашение</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description2">
            <p>
              <b>Пользовательское соглашение</b>
            </p>
            <p>
              <b>I. Преамбула / Общие положения</b>
            </p>
            <p>
              Общество с ограниченной ответственностью промышленно-коммерческая фирма «Полёт» (сокращенно - ООО ПКФ «Полёт») (далее — Оператор), являющееся владельцем сайта и администратором
              доменного имени https://dtlab.tech/ предоставляет интернет-пользователям (далее - Пользователь) доступ к использованию интернет-сайта https://dtlab.tech/ на условиях,
              являющихся предметом настоящего Пользовательского соглашения. В настоящем Пользовательском соглашении Оператор информирует Вас об условиях использовании сайта
              https://dtlab.tech/ (далее – Сайт). Пользователю необходимо внимательно ознакомиться с условиями настоящего Пользовательского соглашения, которое является публичной офертой в
              соответствии со статьей 437 Гражданского кодекса Российской Федерации. Начиная использовать Cайт Пользователь считается принявшим условия Пользовательского соглашения в полном
              объеме, без всяких оговорок и исключений. В случае несогласия Пользователя с какими-либо из положений Пользовательского соглашения, он не вправе использовать Сайт (ему
              необходимо покинуть Сайт). Настоящее Соглашение является публичной офертой. Получая доступ к материалам Сайта Пользователь считается присоединившимся к настоящему Соглашению.
              Использование Сайта также регулируется Политикой конфиденциальности. Адрес места нахождения Оператора: 442961, Пензенская область, город Заречный, проезд Индустриальный,
              строение 6.
            </p>
            <p>
              <b>II. Права и обязанности Оператора</b>
            </p>
            <p>
              Оператор вправе в любое время в одностороннем порядке изменять условия настоящего Соглашения. Такие изменения вступают в силу с момента размещения новой версии Соглашения на
              сайте. При несогласии Пользователя с внесенными изменениями он обязан отказаться от доступа к Сайту, прекратить использование материалов Сайта.
            </p>
            <p>
              <b>III. Ответственность / ограничение использования</b>
            </p>
            <p>
              Все действия, которые совершает Пользователь, введя в форме на Сайте имя (в т.ч. вымышленное), адрес электронной почты (в т.ч. несуществующий), номер телефона (в т.ч.
              несуществующий), считаются произведенными Пользователем. Пользователь самостоятельно несет ответственность за все действия (а также их последствия) в рамках или с
              использованием Сайта. Пользователь не вправе воспроизводить, повторять и копировать, продавать и перепродавать, а также использовать для каких-либо коммерческих целей
              какие-либо части Сайта. Все объекты, доступные на Сайте, в том числе элементы дизайна, текст, графические изображения, иллюстрации, видео, фирменные наименования, коммерческие
              обозначения и другие объекты являются охраняемыми результатами интеллектуальной деятельности, использование которых без согласия правообладателя и без выплаты вознаграждения
              запрещено в соответствии с действующим законодательством РФ. Пользователь понимает, что использование любых результатов интеллектуальной собственности, размещенной на Сайте без
              согласия правообладателя, полученного в установленном порядке, влечет ответственность, предусмотренную действующим гражданским законодательством. Споры между сторонами подлежат
              рассмотрению в суде по месту нахождения Оператора.
            </p>
            <p>
              <b>Пользователь подтверждает, что ознакомлен со всеми пунктами настоящего Соглашения и безусловно принимает их.</b>
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              setOpenUserAgreement(false);
            }}
            autoFocus
          >
            Назад
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
