import Helper from "./Helper";
import axios from "axios";

class ProjectHelper extends Helper {
  static GetAll() {
    var url = Helper.GetServerName() + "/server/rest/project";
    return axios
      .get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return null;
      });
  }
}

export default ProjectHelper;
