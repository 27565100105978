import Helper from "./Helper";
import axios from "axios";

class MessageHelper extends Helper {
  /**
   * Получить всех пользователей
   * @param {number} id Идентификатор портфеля
   * @returns {array}
   */
  static async Send(formData) {
    var url = Helper.GetServerName() + "/server/site/feedback";
    const response = await axios.post(url, formData);
    return response.data;
  }
}

export default MessageHelper;
