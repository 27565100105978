import React from "react";
import styles from "./Slider.module.scss";
import { v4 as uuidv4 } from "uuid";
import SliderMobileItem from "./SliderMobileItem";
import ProjectHelper from "../../helper/ProjectHelper";
import Carousel from "react-material-ui-carousel";

export default function SliderMobile() {
  const [Items, setItems] = React.useState([]);

  // При загрузке страницы
  React.useEffect(() => {
    ProjectHelper.GetAll()
      .then((res) => {
        setItems(res);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        //
      });
  }, []);

  return (
    <Carousel autoPlay={false} indicators={false} navButtonsAlwaysVisible={false} cycleNavigation={false} navButtonsAlwaysInvisible={true} animation="slide" sx={{ height: "100%" }}>
      {Items.map((item) => (
        <SliderMobileItem key={item.id} number={item.number} title={item.title} img={item.img} desc={item.desc} content={item.content} galleryFiles={item.projectFiles}></SliderMobileItem>
      ))}
    </Carousel>
  );
}
