import React from "react";
import styles from "./Button.module.scss";

export default function Button({ children, size }) {
  let clsShadow = [styles.Shadow];
  let cls = [styles.Container];
  if (!!size && size === "small") {
    cls.push(styles.Small);
    clsShadow.push(styles.Small);
  }

  return (
    <div className={styles.Container}>
      <div className={clsShadow.join(" ")}></div>
      <button className={cls.join(" ")}>{children}</button>
    </div>
  );
}
