import React from "react";
import styles from "./Slider.module.scss";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import ImageGallery from "react-image-gallery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/fontawesome-free-solid";

export default function SliderContent({ HasMore, content, desc, galleryFiles }) {
  var images = [];
  const refImg = React.useRef(null);
  if (galleryFiles !== null) {
    galleryFiles.forEach((galleryFile) => {
      let img = {
        fullscreen: !process.env.NODE_ENV || process.env.NODE_ENV === "development" ? "http://dtlab.site:8080" + galleryFile.path : galleryFile.path,
        original: !process.env.NODE_ENV || process.env.NODE_ENV === "development" ? "http://dtlab.site:8080" + galleryFile.path : galleryFile.path,
        thumbnail: !process.env.NODE_ENV || process.env.NODE_ENV === "development" ? "http://dtlab.site:8080" + galleryFile.path : galleryFile.path,
        originalHeight: 300,
      };
      images.push(img);
    });
  }

  const renderCustomControls = () => {
    if (refImg !== null && refImg.current !== null && images !== null && images.length > 0) {
      let index = refImg.current.getCurrentIndex();
      let img = images[index];
      return (
        <a href={img.fullscreen} target="_blank" className={styles.ButtonFullscreen}>
          <FontAwesomeIcon icon={faExpand} color="white" size="lg" />
        </a>
      );
    }
  };
  return (
    <Box className={styles.Content}>
      <Box className={styles.ContentText} sx={{ opacity: HasMore ? 1 : 0 }}>
        <Grid container spacing={1} sx={{ height: "100%" }}>
          {images === null || images.length === 0 ? (
            <>
              <Grid item xs={12} sx={{ height: "100%" }}>
                <Box className={[styles.BoxText, styles.TextScroll].join(" ")}>{content}</Box>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={6} sx={{ height: "100%" }}>
                <Box className={[styles.BoxText, styles.TextScroll].join(" ")}>{content}</Box>
              </Grid>
              <Grid item xs={6} sx={{ height: "100%" }}>
                <ImageGallery
                  ref={refImg}
                  items={images}
                  showPlayButton={false}
                  showFullscreenButton={false}
                  useBrowserFullscreen={true}
                  slideOnThumbnailOver={true}
                  renderCustomControls={renderCustomControls}
                ></ImageGallery>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      <Box className={styles.ContentText} sx={{ opacity: HasMore ? 0 : 1, pointerEvents: HasMore ? "none" : "auto" }}>
        {desc}
      </Box>
    </Box>
  );
}
