import React from "react";
import styles from "./Section.module.scss";
import { Grid, Container } from "@mui/material";
import Section from "../hoc/Section/Section";
import { Box } from "@mui/system";
import FormProject from "../component/Form/FormProject";

const idSection = "DiscussProject";

export default function DiscussProject() {
  return (
    <Box className={styles.DiscussProject}>
      <Container maxWidth="lg">
        <Section id={idSection} title={""} sx={{ p: "0" }} isHiddenLine={true}>
          <Grid container>
            <Grid item xs={12}>
              <FormProject />
            </Grid>
          </Grid>
        </Section>
      </Container>
    </Box>
  );
}
