import React from "react";
import styles from "./Section.module.scss";
import { Container, Grid } from "@mui/material";
import Section from "../hoc/Section/Section";
import FormFeedback from "../component/Form/FormFeedback";
import { Box } from "@mui/system";

const idSection = "AboutSection";

export default function AboutSection() {
  return (
    <Container maxWidth="lg">
      <Section idSection={idSection} title={"О нас"}>
        <Grid container>
          <Grid item xs={12} md={8}>
            <Box className={styles.AboutSectionText}>
              <Box sx={{ typography: "Display1", mt: "30px" }}>
                <Box component="span" sx={{ color: (theme) => theme.palette.primary.main }}>
                  Программное
                  <br />
                  обеспечение
                </Box>{" "}
                для
                <br />
                ваших задач
              </Box>
              <Box sx={{ typography: "body1", mt: "45px" }}>
                <b>ЛЦТ</b> – команда ИТ специалистов, увлечённых разработкой
                <br />
                всторенного и прикладного программного обеспечения.
                <br />
                Экспертные знания в передовых направлениях IT отрасли
                <br />и использование <b>самых современных инструментов</b>
                <br />
                разработки ПО позволяют нам создавать комплексные
                <br />
                решения <b>любого уровня сложности</b> и масштаба.
                <br />
                Мы создаем эксклюзивные решения в сфере
                <br />
                информационных технологий.
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className={styles.WrapperForm}>
              <FormFeedback />
            </Box>
          </Grid>
        </Grid>
      </Section>
    </Container>
  );
}
