import './App.scss';
import AboutSection from './section/AboutSection';
import Header from "./component/Header/Header";
import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { lightTheme } from "./themes";
import ProjectsSection from './section/ProjectsSection';
import ContactSection from './section/ContactSection';
import DiscussProjectSection from './section/DiscussProjectSection';
import Footer from './component/Footer/Footer';




function App() {
  return (
    <React.Fragment>
      <ThemeProvider theme={lightTheme}>
        <div className="App">
          <Header />
          <AboutSection />
          <ProjectsSection />
          <ContactSection />
          <DiscussProjectSection />
          <Footer />
        </div>
      </ThemeProvider>
    </React.Fragment>

  );
}

export default App;
